<template>
  <div class="topic-list-item" @click.prevent="$emit('selected', topic)">
    <div class="left" :style="leftStyle"></div>
    <div class="right">
      <div class="name">
        {{ topic["$v"]["displayName"] }}
      </div>
      <div class="context">
        {{ topic["$v"]["contextQuote"] }}
      </div>
    </div>
  </div>
</template>

<script>
import topics from "../../../../api/guidance/topics";
export default {
  props: ["topic", "tenantId", "orgId"],
  data() {
    return {
      isLoadingButtonStripUrl: false,
      buttonStripUrl: null,
    };
  },
  computed: {
    leftStyle() {
      if (this.buttonStripUrl) {
        return {
          backgroundImage: "url(" + this.buttonStripUrl.url + ")",
        };
      }
      return {
        backgroundColor: "rgba(81, 85, 234, 0.1)",
      };
    },
  },
  created() {
    this.getButtonStripUrl();
  },
  methods: {
    getButtonStripUrl() {
      let vm = this;
      vm.isLoadingButtonStripUrl = true;
      topics
        .getTopicButtonStripProtectedUrl(this.tenantId, this.topic["$v"]["id"])
        .then((r) => {
          vm.isLoadingButtonStripUrl = false;
          vm.buttonStripUrl = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.buttonStripUrl = null;
          // vm.$message.error("Error loading image");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.topic-list-item {
  background: #fff;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #fff;
  overflow: hidden;

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  display: flex;
  .left {
    flex-shrink: 1;
    min-width: 100px;
    width: 100px;
    max-width: 100px;
    background-size: cover;
    background-position: center;
      height: 200px;
    min-height: 200px;
  }
  .right {
    // padding: 20px;
    padding: 25px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-items: center;
    height: 100%;
    .name {
      // margin-bottom: 5px;
      font-weight: 500;
      font-size: 19px;
      margin-bottom: 12px;
    }
    .context {
      font-size: 15px;
    }
    position: relative;
  }
}
</style>