<template>
  <div class="admin-guidance-topics">
    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">
        <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin/guidance')"
          icon="left"
        ></a-button>

        <admin-org-indicator></admin-org-indicator>

        Admin - Guidance - Topics
      </h1>
      <div class="actions">
        <a-button
          @click.prevent="loadTopics"
          :disabled="isLoading"
          class="button-margin-left btn-rounded"
          icon="reload"
          size="large"
          type="default"
          >Refresh</a-button
        >

        <a-button
        v-if="showCreateButton"
          @click.prevent="createTopic"
          class="button-margin-left btn-rounded"
          icon="plus"
          size="large"
          type="primary"
          >Create</a-button
        >
      </div>
    </div>
    <!-- / Page Header -->

    <!-- List wrapper -->
    <div class="topics-list-wrapper">
      <!-- Loading -->
      <div class="loader" v-if="isLoading">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <div class="admin-guidance-context-selector-wrapper">
        <admin-guidance-context-selector v-if="!isLoading"></admin-guidance-context-selector>
      </div>

      <!-- No topics -->
      <a-alert
        v-if="!isLoading && topicsToShow.length == 0"
        type="info"
        message="No topics to show"
      >
      </a-alert>
      <!-- / No topics -->

      <!-- Topics list -->
      <div class="topics-list" v-if="!isLoading && topicsToShow.length" :key="ownerIdFilter">
        <a-row type="flex" :gutter="20">
          <a-col :span="colSpan" v-for="topic in topicsToShow" :key="topic.id">
            <topic-list-item
              :topic="topic"
              :tenant-id="tenantId"
              :org-id="selectedOrganisation.id"
              @selected="() => navigateToTopic(topic)"
            ></topic-list-item>
          </a-col>
        </a-row>
      </div>
      <!-- / Topics list -->
    </div>
    <!-- / List wrapper -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TopicListItem from './Topics/TopicListItem.vue';
import AdminGuidanceContextSelector from "../../../components/Admin/Guidance/AdminGuidanceContextSelector.vue";
export default {
  components: {TopicListItem, AdminGuidanceContextSelector},

  data() {
    return {
      windowWidth: window.innerWidth
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    this.loadTopics();
  },

  watch: {
    selectedOrganisation() {
      this.loadTopics();
    },
  },

  methods: {
    ...mapActions("adminTopics", {
      loadTopics: "loadTopics",
    }),

    navigateToTopic(topic) {
      this.$router.push("/admin/guidance/topics/" + topic['$v'].id);
    },

    createTopic() {
      this.$router.push("/admin/guidance/topics/create");
    },
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),

    ...mapGetters("adminGuidance", {
      ownerIdFilter: 'ownerIdFilter'
    }),

    ...mapGetters("adminTopics", {
      isLoading: "isLoading",
      topicsToShow: "topicsToShow",
    }),

    colSpan() {
      return this.windowWidth < 1200 ? 24 : 12;
    },

    showCreateButton() {
      return (!this.ownerIdFilter || this.ownerIdFilter == this.selectedOrganisation.id)
    }
  },
};
</script>

<style scoped lang="scss">
.topic-list-item {
  margin-bottom: 15px;
}

.button-margin-left {
  margin-left: 15px;
}

.admin-guidance-context-selector-wrapper {
  margin-bottom: 30px;
}
</style>